// TODO
// waiter/xxx, waiter-xxxのpathは全部pos/xxx, pos-xxxにした方が辻褄があう
// e.g. POSの日報はwaiter権限だと見れちゃいけないけど、adminなら見せる、とか
// そういったように使いたいのでwaiter/xxxの名前空間だと齟齬がある

const waiter = [
  //
  //* ——— ホール ——————————————————
  //
  {
    path: '/:club_slug/waiter/halls',
    name: 'waiter-halls',
    component: () => import('@/views/waiter/Halls.vue'),
    meta: {
      layout: 'waiter',
      resource: 'Waiter',
    },
  },

  //
  //* ——— 日報 ——————————————————
  //
  {
    path: '/:club_slug/waiter/daily-reports',
    name: 'waiter-daily-reports',
    component: () => import('@/views/waiter/DailyReports.vue'),
    meta: {
      layout: 'admin',
      resource: 'Admin',
    },
  },

  //
  //* ——— オーダー ——————————————————
  //
  {
    path: '/:club_slug/waiter/orders',
    name: 'waiter-orders',
    component: () => import('@/views/waiter/Orders.vue'),
    meta: {
      layout: 'waiter',
      resource: 'Waiter',
    },
  },

  //
  //* ——— 勤怠 ——————————————————
  //
  {
    path: '/:club_slug/waiter/timecards',
    name: 'waiter-timecards',
    component: () => import('@/views/waiter/Timecards.vue'),
    meta: {
      layout: 'waiter',
      resource: 'Waiter',
    },
  },

  //
  //* ——— データ ——————————————————
  //
  {
    path: '/:club_slug/waiter/data',
    name: 'waiter-data',
    component: () => import('@/views/waiter/Data.vue'),
    meta: {
      layout: 'waiter',
      resource: 'Waiter',
    },
  },
  //
  //* ——— データ start ——————————————————
  //
  //
  //* ——— 卓 ——————————————————
  //
  {
    path: '/:club_slug/waiter/data/tables',
    name: 'waiter-data-tables',
    meta: {
      layout: 'waiter',
      resource: 'Waiter',
    },
    redirect: { name: 'waiter-data' },
  },

  //
  //* ——— 伝票 ——————————————————
  //
  {
    path: '/:club_slug/waiter/data/receipts',
    name: 'waiter-data-receipts',
    meta: {
      layout: 'waiter',
      resource: 'Waiter',
    },
    redirect: { name: 'waiter-data' },
  },

  //
  //* ——— 売掛 ——————————————————
  //
  {
    path: '/:club_slug/waiter/data/balance-receivables',
    name: 'waiter-data-balance-receivables',
    meta: {
      layout: 'waiter',
      resource: 'Waiter',
    },
    redirect: { name: 'waiter-data' },
  },

  //
  //* ——— タイムカード ——————————————————
  //
  {
    path: '/:club_slug/waiter/data/timecards',
    name: 'waiter-data-timecards',
    meta: {
      layout: 'waiter',
      resource: 'Waiter',
    },
    redirect: { name: 'waiter-data' },
  },

  //
  //* ——— 販売履歴 ——————————————————
  //
  {
    path: '/:club_slug/waiter/data/vending-histories',
    name: 'waiter-data-vending-histories',
    meta: {
      layout: 'waiter',
      resource: 'Waiter',
    },
    redirect: { name: 'waiter-data' },
  },

  //
  //* ——— リファラル履歴 ——————————————————
  //
  {
    path: '/:club_slug/waiter/data/referring-histories',
    name: 'waiter-data-referring-histories',
    meta: {
      layout: 'waiter',
      resource: 'Waiter',
    },
    redirect: { name: 'waiter-data' },
  },
  //
  //* ——— データ end ——————————————————
  //

  //
  //* ——— 基本設定 start ——————————————————
  //
  //
  //* ——— ホール ——————————————————
  //
  {
    path: '/:club_slug/waiter/settings/halls',
    name: 'waiter-settings-halls',
    component: () => import('@/views/waiter/settings/Halls.vue'),
    meta: {
      layout: 'waiter',
      resource: 'Waiter',
    },
  },

  //
  //* ——— メニュー ——————————————————
  //
  {
    path: '/:club_slug/waiter/settings/vendibles',
    name: 'waiter-settings-vendibles',
    component: () => import('@/views/waiter/settings/Vendibles.vue'),
    meta: {
      layout: 'waiter',
      resource: 'Waiter',
    },
  },

  //
  //* ——— 商品タグ ——————————————————
  //
  {
    path: '/:club_slug/waiter/settings/vendible-tags',
    name: 'waiter-settings-vendible-tags',
    component: () => import('@/views/waiter/settings/VendibleTags.vue'),
    meta: {
      layout: 'waiter',
      resource: 'Waiter',
    },
  },

  //
  //* ——— リファラル ——————————————————
  //
  {
    path: '/:club_slug/waiter/settings/referrals',
    name: 'waiter-settings-referrals',
    component: () => import('@/views/waiter/settings/Referrals.vue'),
    meta: {
      layout: 'waiter',
      resource: 'Waiter',
    },
  },

  //
  //* ——— 顧客タグ ——————————————————
  //
  {
    path: '/:club_slug/waiter/settings/customer-tags',
    name: 'waiter-settings-customer-tags',
    component: () => import('@/views/waiter/settings/CustomerTags.vue'),
    meta: {
      layout: 'waiter',
      resource: 'Waiter',
    },
  },

  //
  //* ——— 売上計上トリガー ——————————————————
  //
  {
    path: '/:club_slug/waiter/settings/vending-contribution-triggers',
    name: 'waiter-settings-vending-contribution-triggers',
    component: () => import('@/views/waiter/settings/VendingContributionTriggers.vue'),
    meta: {
      layout: 'waiter',
      resource: 'Waiter',
    },
  },

  //
  //* ——— ユーザー ——————————————————
  //
  {
    path: '/:club_slug/waiter/settings/users',
    name: 'waiter-settings-users',
    component: () => import('@/views/waiter/settings/Users.vue'),
    meta: {
      layout: 'waiter',
      resource: 'Waiter',
    },
  },

  //
  //* ——— シフト ——————————————————
  //
  {
    path: '/:club_slug/waiter/work-schedules',
    name: 'waiter-settings-work-schedules',
    component: () => import('@/views/waiter/settings/WorkSchedules.vue'),
    meta: {
      layout: 'waiter',
      resource: 'Waiter',
    },
  },

  //
  //* ——— 諸入費タグ ——————————————————
  //
  {
    path: '/:club_slug/waiter/expense-tags',
    name: 'waiter-settings-book-tags',
    component: () => import('@/views/waiter/settings/BookTags.vue'),
    meta: {
      layout: 'waiter',
      resource: 'Waiter',
    },
  },

  //
  //* ——— 店舗 ——————————————————
  //
  {
    path: '/:club_slug/waiter/club',
    name: 'waiter-settings-club',
    component: () => import('@/views/waiter/settings/Club.vue'),
    meta: {
      layout: 'waiter',
      resource: 'Waiter',
    },
  },

  //
  //* ——— 基本設定 end  ——————————————————
  //

  {
    path: '/:club_slug/waiter/cash-register-transactions',
    name: 'waiter-cash-register-transactions',
    component: () => import('@/views/waiter/CashRegisterTransactions.vue'),
    meta: {
      layout: 'waiter',
      resource: 'Waiter',
    },
  },

  {
    path: '/tutorial/order',
    name: 'tutorial-order',
    component: () => import('@/views/tutorial/OrderTutorial.vue'),
    meta: {
      layout: 'blank',
      resource: 'Waiter',
    },
  },
]

export default waiter
