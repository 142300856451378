// axios
import axios from 'axios'
import Vue from 'vue'
import { wrapper } from 'axios-cookiejar-support'
import { CookieJar } from 'tough-cookie'

const clearAuthLocalStorage = () => {
  localStorage.removeItem('currentUser')
  localStorage.removeItem('currentClub')
  localStorage.removeItem('userAbilities')
}

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
})
wrapper(axiosIns)
axiosIns.defaults.jar = new CookieJar()
axiosIns.defaults.xsrfCookieName = 'CSRF-Token'
axiosIns.defaults.xsrfHeaderName = 'X-CSRF-Token'

axiosIns.interceptors.request.use(
  config => {
    // API v2 のリクエストであれば baseURL を変更
    if (config.url.includes('/v2/')) {
      // eslint-disable-next-line no-param-reassign
      config.baseURL = process.env.VUE_APP_API_ENDPOINT_BASE
    }

    return config
  },
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (!error.response) {
      if (error.code === 'ECONNABORTED' || error.message === 'Network Error') {
        if (Vue.prototype.$toast) {
          Vue.prototype.$toast.error('リクエストがタイムアウトしました')
        }
      }
    }

    const { status } = error?.response || {}
    switch (status) {
      case 401:
        clearAuthLocalStorage()
        if (window.location.pathname !== '/login') window.location = '/login'
        break
      default:
        break
    }

    return Promise.reject(error)
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
