<template>
  <div>
    <v-text-field
      v-model="customerNameRef"
      label="宛名"
      outlined
      dense
    >
      <template #append>
        様
      </template>
    </v-text-field>
    <v-select
      v-model="receiptNoteRef"
      label="但"
      :items="receiptNotePresets"
      item-value="attributes.content"
      item-text="attributes.content"
      no-data-text="選択可能なデータがありません"
      outlined
      dense
      clearable
      @click:clear="receiptNoteRef = ''"
    />
    <v-menu
      v-model="isPickingIssueDate"
      transition="scale-transition"
      min-width="auto"
    >
      <template #activator="{ on: isPickingIssueDateOn, attrs: isPickingIssueDateAttrs }">
        <v-text-field
          :value="dateRef ? format(dateRef, 'yyyy年M月d日') : ''"
          label="発行日"
          readonly
          clearable
          outlined
          dense
          v-bind="isPickingIssueDateAttrs"
          v-on="isPickingIssueDateOn"
          @click:clear="dateRef = ''"
        ></v-text-field>
      </template>
      <v-date-picker
        :value="dateRef ? format(dateRef, 'yyyy-MM-dd') : ''"
        locale="ja"
        :day-format="date => new Date(date).getDate()"
        no-title
        @change="dateRef = new Date($event)"
      />
    </v-menu>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { useVModels } from '@vueuse/core'
import { format } from 'date-fns'

export default {
  props: {
    customerName: {
      type: String,
      default: '',
    },
    receiptNote: {
      type: String,
      default: '',
    },
    date: {
      type: [Date, String],
      default: () => new Date(),
    },
    receiptNotePresets: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const {
      customerName: customerNameRef,
      receiptNote: receiptNoteRef,
      date: dateRef,
    } = useVModels(props, emit)

    const isPickingIssueDate = ref(false)

    return {
      customerNameRef,
      receiptNoteRef,
      dateRef,
      isPickingIssueDate,

      format,
    }
  },
}
</script>
