import axiosIns from '@/plugins/axios'

export default {
  getActiveCastCount() {
    return axiosIns.get('/v2/monitor/timecards/active_cast_count')
  },
  getCastWorkingTime() {
    return axiosIns.get('/v2/monitor/timecards/cast_working_time')
  },
}
