import { serialize } from 'object-to-formdata'
import axiosIns from '@/plugins/axios'

export default {
  getReceiptNotePresets() {
    return axiosIns.get('/v2/utility/settings/receipt_note_presets')
  },
  createReceiptNotePreset(content) {
    const formData = serialize({ content })

    return axiosIns.post('/v2/utility/settings/receipt_note_presets', formData)
  },
  updateReceiptNotePreset(id, content) {
    const formData = serialize({ content })

    return axiosIns.put(`/v2/utility/settings/receipt_note_presets/${id}`, formData)
  },
  deleteReceiptNotePreset(id) {
    return axiosIns.delete(`/v2/utility/settings/receipt_note_presets/${id}`)
  },
}
